import store from '../store'
import Api from './Api'

class Auth {
	constructor() {
		this.host = process.env.VUE_APP_API_ORIGIN;
		this.api = new Api()
	}

	async login(email, password) {
		await this.api.post('users/login', { "email": email, "password": password })
			.then((res) => {
				store.commit('user', res.data);
			})
			.catch((error) => {
				throw new Error(error.response.status);
			}
		);
	}

	logout() {
		store.commit('removeUser');
	}

	//get user() {
	//	return store.state.auth;
	//}

	get isAdmin() {
		return store.state.userPermissionId === 1;
	}

	//get token() {
	//	return store.state.token;
	//}

	//get isLoggedIn() {
	//	return store.state.auth !== null;
	//}
}

export default Auth
