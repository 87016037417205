import { ToastProgrammatic as Toast } from 'buefy'
//import moment from 'moment'

class Utils {
	constructor(isDebug) {
		this.isDebug = isDebug;
	}

	log(str) {
		if (this.isDebug === 'true') {
			console.log(str);
		}
	}

	_toast(msg, type) {
		Toast.open({
			duration: 3000,
			position: 'is-top',
			type: type,
			message: msg,
		});
	}

	toastSuccess(msg) {
		this._toast(msg, 'is-success');
	}

	toastError(msg) {
		this._toast(msg, 'is-danger');
	}

	toCamelCase(str) {
		return str.split('_').map(function(word,index){
			if (index === 0) {
				return word.toLowerCase();
			}
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}).join('');
	}

	zeroPadding(num, digit) {
		let pad = '';
		for (let i = 0; i < digit; i++) {
			pad += '0';
		}
		return (pad + num).slice(-digit);
	}

	hasError(errors, key) {
		return errors[key] !== '';
	}

	extractFileExtension(fileName) {
		let tmp = fileName.split('.');
		return tmp[tmp.length - 1];
	}

	createCsvContents(data, delim = ',', isQuote = false) {
		let csv = '\ufeff';

		for (let row in data) {
			let line = '';
			for (let col in data[row]) {
				if (!isQuote) {
					line += data[row][col] + delim;
				} else {
					line += `"${data[row][col]}"${delim}`;
				}
			}
			line = line.slice(0, -1);
			csv += line + '\n';
		}

		return csv;
	}

	downloadCSV(data, fileName, isQuote = false) {
		let csv = this.createCsvContents(data, ',', isQuote);
		let blob = new Blob([csv], { type: 'text/csv' })
		let link = document.createElement('a')
		link.href = window.URL.createObjectURL(blob)
		link.download = fileName
		link.click()
	}

	convertToHhalfWidthCharacter(str) {
		return str.replace(/[０-９]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 65248);
		})
		.replace(/[‐－―]/g, '-');
	}

	isObject(obj) {
		return Object.prototype.toString.call(obj) === "[object Object]";
	}

	//createYearSelectOption() {
	//	let years = [];
	//	for (let i = moment().year(); i >= 1920; i--) {
	//		years.push(i);
	//	}
	//	return years;
	//}

	toBlob(base64, type = 'application/pdf') {
		var bin = atob(base64.replace(/^.*,/, ''));
		var buffer = new Uint8Array(bin.length);
		for (var i = 0; i < bin.length; i++) {
			buffer[i] = bin.charCodeAt(i);
		}
		// Blobを作成
		try{
			var blob = new Blob([buffer.buffer], {
				type: type
			});
		}catch (e){
			return false;
		}
		return blob;
	}

	hyperlink(str) {
		let reg = new RegExp("((https?)(://[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))", 'g');
		return str.replace(reg,'<a href="$1" target="_blank">$1</a>');
	}
}

export default Utils
