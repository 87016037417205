<template>
	<div id="app">
		<nav-bar @logout="onLogoutClick"/>
		<aside-menu
			:menu="menu"
			:menu-bottom="menuBottom"
			@menu-click="menuClick"
			:class="{'has-secondary':!!menuSecondary}" />
		<aside-menu
			v-if="menuSecondary"
			:menu="menuSecondary"
			:is-secondary="true"
			:label="menuSecondaryLabel"
			:icon="menuSecondaryIcon"
			@menu-click="menuClick"
			@close="menuSecondaryCloseClick"/>
		<router-view/>
		<aside-right/>
		<footer-bar/>
		<overlay/>
		<a href="" v-show="isCartButtonShow" class="is-hidden-desktop cart-button" @click.prevent="$router.push({ name: 'cart'})"><i class="fas fa-shopping-cart"></i></a>
	</div>
</template>

<style lang="scss">
@import 'scss/_vars.scss';
@import 'scss/_mixins.scss';

$spaces: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50); // 0から50pxまでを用意
$sides: (top, bottom, left, right); // 方向性
@each $space in $spaces {
	@each $side in $sides {
		.m#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px !important;
		}

		.p#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px !important;
		}
	}
}

.cart-button {
	display: block;
	position: fixed;
	background-color: black;
	width: 65px;
	height: 65px;
	padding-top: 14px;
	color: #fff;
	border-radius: 50%;
	bottom: 40px;
	right: 20px;
	font-size: 24px;
	text-align: center;
	opacity: .7;
}

.button {
	width: 120px;
}

label.require:after {
	content: ' *';
	color: red;
}

.field-label {
	margin-bottom: 20px;
}

table button.is-small {
	width: 50px;
}

.horizontal-center {
	display: flex;
	align-items: center;
}

.field.is-grouped {
	align-items: center;
}

.form-buttons {
	margin-top: 25px;
}

.slide-enter-active, .slide-leave-active {
	transition: all .2s;
}
.slide-enter, .slide-leave-to {
	height: 0;
}

.wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 30px 10px 50px;
}

.word-wrap {
	white-space: pre-wrap;
	word-wrap: break-word;
}

body {
	font-size: 1.1em;
}


img.preview {
	width : auto;
	max-width: 100%;
	margin: 0 auto;
	vertical-align: bottom;
}

.modal-card-body {
	max-height: 50vh;
}

@media screen and (min-width: $pcSizeWidth) {
	.wrapper {
		width: 960px;
	}

	.modal-card-body {
		max-height: initial;
	}
}
</style>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import Overlay from '@/components/Overlay'
import AsideRight from '@/components/AsideRight'

export default {
	name: 'App',
	components: {
		AsideRight,
		Overlay,
		FooterBar,
		AsideMenu,
		NavBar
	},
	data () {
		return {
			menuSecondary: null,
			menuSecondaryLabel: null,
			menuSecondaryIcon: null
		}
	},
	computed: {
		isCartButtonShow: function() {
			return this.$route.name !== 'login' && this.$route.name !== 'cart';
		},
		menu () {
			let menu = [
				'',
				[
					{
						to: '/',
						icon: 'home',
						label: 'ホーム'
					}
				],
				[
					{
						to: '/history',
						icon: 'history',
						label: 'オーダー履歴'
					}
				],
			];

			if (this.$auth.isAdmin) {
				menu.push(
					[
						{
							to: '/account',
							icon: 'user',
							label: 'アカウント'
						}
					],
				);
			}

			return menu;
		},
		menuBottom () {
			return [
				{
					action: 'logout',
					icon: 'logout',
					label: 'Log out',
					state: 'info'
				}
			]
		},
		...mapState([
			'isOverlayVisible'
		])
	},
	created () {
	},
	mounted () {
	},
	methods: {
		onLogoutClick: function() {
			this.$auth.logout();
			this.$router.push({ name: 'login' });
		},
		menuClick (item) {
			if (item.menuSecondary) {
				this.menuSecondary = item.menuSecondary
				this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
				this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

				this.$store.commit('asideActiveForcedKeyToggle', item)
				this.$store.commit('overlayToggle', true)
			} else if (item.action && item.action === 'logout') {
				this.$buefy.toast.open({
					message: 'Log out clicked',
					type: 'is-danger',
					queue: false
				})
			}
		},
		menuSecondaryCloseClick () {
			this.$store.commit('overlayToggle', false)
		},
		menuSecondaryClose () {
			this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
			this.$store.commit('asideActiveForcedKeyToggle', null)
		}
	},
	watch: {
		isOverlayVisible (newValue) {
			if (!newValue) {
				this.menuSecondaryClose()
			}
		}
	}
}
</script>
